import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Batholith from './pages/Batholith'
import CV from './pages/CV'
import Menu from './components/Menu'
import Home from './pages/Home'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  app: {
    position: 'relative',
  },
  padding: {
    paddingTop: 40,
    paddingLeft: '20px'
  },
  drawer: {
    position: 'fixed',
    width: 150,
    backgroundColor: 'white',
    zIndex: 10,
    height: 'calc(100% - 33px)'
  },
  content: {
    marginTop: '20px',
    marginLeft: 150
  }
})
function App () {
  const classes = useStyles()
  return (
    <BrowserRouter>
      <div className={ `${ classes.drawer } ${ classes.padding }` }>
        <Menu />
      </div>
      <div className={ `${ classes.app } ${ classes.padding }` }>
        <div />
        <div className={ `${ classes.content }` }>
          <Switch>
            <Route path="/batholith" component={ Batholith } />
            <Route path="/cv" component={ CV } />
            <Route component={ Home } />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
