import ClickyImages from '../components/ClickyImages'
import { BImages } from '../images'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    columnGap: '10px',
    gridTemplateColumns: '250px auto'
  },
  block: {
    display: 'grid',
    height: 'fit-content',
    gridTemplateColumns: 'auto',
    rowGap: '10px'
  },
  header: {
    fontFamily: 'Georgia, "Times New Roman", Times, serif',
    fontSize: 14,
    color: '#797979',
  }

})
export default function Batholith () {
  const classes = useStyles()
  return (
    <div className={ classes.container }>
      <div className={ classes.block }>
        <p className={ classes.header }><em>Batholith</em> (2020)</p>
        <p>What is under this place is a huge mound of granite. In the past it was quarried, and many industrial activites once took place here.</p>
        <p>With our absence and lack of activity, a gap in the land market is formed and is left to anything that suits the environment.</p>
        <p>This series of images is an ongoing project exploring the ecology which over the past 100 years has been readjusting to a churned, uprooted landscape, where materials from 280 million years ago have re-entered our world and now lie on its surface.</p>
      </div>
      <ClickyImages images={ BImages } />
    </div>
  )
}