import { makeStyles } from '@material-ui/core'
import RocheScan014 from '../images/Harvey_Images/200107rocheScan014.jpg'
import Scan004 from '../images/Harvey_Images/202003Scan004.jpg'
import Fruits from '../images/Harvey_Images/3 fruits.jpg'
import Barn from '../images/Harvey_Images/Barn.jpg'
import Lillies from '../images/Harvey_Images/Lillies.jpg'
import OldWindTurbine from '../images/Harvey_Images/old wind turbine.jpg'
import Sink from '../images/Harvey_Images/sink.jpg'

const useStyles = makeStyles({
  scroll: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '& img': {
      marginRight: 10,
      marginLeft: 10,
    }
  },
  button: {
    minWidth: 100,
    paddingRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    '& p': {
      width: 30,
      fontSize: '12px',
      fontStyle: 'italic',
      color: '#919191',
    }
  },
  m20: {
    marginRight: '200px !important'
  }
})

export default function Home () {
  const classes = useStyles()
  function goBack () {
    document.body.scrollTo({ left: 0, behavior: 'smooth' })
  }

  return (
    <div className={ classes.scroll }>
      <img className={ classes.m20 } alt="My photograph" src={ Fruits } height="600" />
      <img className={ classes.m20 } alt="My photograph" src={ OldWindTurbine } height="600" />
      <img alt="My photograph" src={ RocheScan014 } height="600" />
      <img className={ classes.m20 } alt="My photograph" src={ Scan004 } height="600" />
      <img className={ classes.m20 } alt="My photograph" src={ Barn } height="600" />
      <img alt="My photograph" src={ Lillies } height="600" />
      <img alt="My photograph" src={ Sink } height="600" />
      <div className={ classes.button } onClick={ goBack }>
        <p>Back to start</p>
      </div>
    </div>
  )
}