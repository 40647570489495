import B1 from './images/Batholith_Images/1.jpg'
import B10 from './images/Batholith_Images/10.jpg'
import B11 from './images/Batholith_Images/11.jpg'
import B12 from './images/Batholith_Images/12.jpg'
import B13 from './images/Batholith_Images/13.jpg'
import B14 from './images/Batholith_Images/14.jpg'
import B15 from './images/Batholith_Images/15.jpg'
import B16 from './images/Batholith_Images/16.jpg'
import B17 from './images/Batholith_Images/17.jpg'
import B18 from './images/Batholith_Images/18.jpg'
import B19 from './images/Batholith_Images/19.jpg'
import B2 from './images/Batholith_Images/2.jpg'
import B20 from './images/Batholith_Images/20.jpg'
import B21 from './images/Batholith_Images/21.jpg'
import B22 from './images/Batholith_Images/22.jpg'
import B23 from './images/Batholith_Images/23.jpg'
import B24 from './images/Batholith_Images/24.jpg'
import B25 from './images/Batholith_Images/25.jpg'
import B3 from './images/Batholith_Images/3.jpg'
import B4 from './images/Batholith_Images/4.jpg'
import B5 from './images/Batholith_Images/5.jpg'
import B6 from './images/Batholith_Images/6.jpg'
import B7 from './images/Batholith_Images/7.jpg'
import B8 from './images/Batholith_Images/8.jpg'
import B9 from './images/Batholith_Images/9.jpg'


export const BImages = [
  B1,
  B2,
  B3,
  B4,
  B5,
  B6,
  B7,
  B8,
  B9,
  B10,
  B11,
  B12,
  B13,
  B14,
  B15,
  B16,
  B17,
  B18,
  B19,
  B20,
  B21,
  B22,
  B23,
  B24,
  B25,
]