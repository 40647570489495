import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
const useStyles = makeStyles({
  menu: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    rowGap: '20px',
    height: 'fit-content',
    color: '#919191'
  },
  mHeader: {
    fontFamily: 'Georgia, "Times New Roman", Times, serif',
    fontSize: 14,
    color: '#3A3A3A',
    textDecoration: 'none',
  },
  mNav: {
    color: 'inherit',
    textDecoration: 'none',
    fontSize: 12
  }
})

export default function Menu () {
  const classes = useStyles()
  return (
    <div className={ classes.menu }>
      <Link className={ classes.mHeader } to="/">HARVEY GORST</Link>
      <Link className={ classes.mNav } style={ { fontStyle: 'italic' } } to="/batholith">Batholith</Link>
      <a className={ classes.mNav } target="_blank" rel="noreferrer" href="mailto:harvgorst@gmail.com">Contact</a>
      <Link className={ classes.mNav } to="/cv">CV</Link>
    </div>
  )
}