import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'grid',
    rowGap: '10px',
    height: 'auto',
    gridTemplateColumns: 'auto',
  }
})

export default function CV () {
  const classes = useStyles()
  return (
    <div>
      <div className={ classes.container }>
        <p>b. 1998</p>
        <p>2018 - 2021 ....... BA Photography, Falmouth University, Cornwall</p>
        <p>
          2020 - Present....
          <i>Gardener in Residence, </i>
          <a rel="noreferrer" target="_blank" href="https://eotwgarden.org.uk/">
            <u>The End of the World Garden</u>
          </a>, Cornwall, UK
        </p>
        <p>
          2018 - Present....
          <i>Gallery Assistant, </i>
          <a rel="noreferrer" target="_blank" href="https://www.kestlebarton.co.uk/current-programme/">
            <u>Kestle Barton</u>
          </a>
          <span>, Manaccan, Cornwall</span>
        </p>
        <div />
        <p>
          <div />
          Group Shows
        </p>
        <p>
          2020 ................... <i><a rel="noreferrer" target="_blank" href="https://asob.uk/upper-gallery-1"><u>A Sense of Being</u></a></i>, Online Exhibition.
        </p>
        <p>
          ............................ <i>Libertad</i>, Falmouth University Gallery, Cornwall, UK.
        </p>
        <p>Print</p>
        <p>2020 ................... <i>Approach Zine - Vol. 1</i>,  Six By Seven, Winchester, UK.</p>
      </div>
    </div >
  )
}