import { makeStyles } from '@material-ui/core'
import { useState } from 'react'

const useStyles = makeStyles({
  container: {
    width: 'fit-content',
    height: 'fit-content'
  }
})
interface Props {
  images: string[]
}
export default function ClickyImages (props: Props) {
  const [index, setIndex] = useState(0)
  const classes = useStyles()

  function next () {
    let i = index + 1
    if (i > props.images.length - 1) {
      i = 0
    }
    setIndex(i)
  }

  function prev () {
    let i = index - 1
    if (i < 0) {
      i = props.images.length - 1
    }
    setIndex(i)
  }

  const buttons = (
    <div className="row between" style={ { position: 'absolute', color: 'white', width: '100%', height: '100%' } }>
      <div style={ { cursor: 'w-resize' } } className="img-nav" onClick={ () => prev() }>
      </div>
      <div style={ { cursor: 'e-resize' } } className="img-nav" onClick={ () => next() }>
      </div>
    </div>
  )


  return (
    <div className={ classes.container }>
      <div className="img-div">
        <img alt={ props.images[index] } src={ props.images[index] } />
        { buttons }
      </div>
    </div>
  )
}